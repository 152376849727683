import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import styled from 'styled-components';
import MagicRainbowButton from '../components/rainbowButton/MagicRainbowButton';

const PageTemplate = ({ data }) => {
  const { frontmatter, excerpt, html } = data.markdownRemark;

  return (
    <Layout
      title={frontmatter.title}
      description={frontmatter.description || excerpt}
      socialImage={
        frontmatter.social_image ? frontmatter.social_image.absolutePath : ''
      }
    >
        <Wrapper>
            <h1 className='work-title'>Here are some of the websites I have worked on in the past few years.</h1>
            <br />
            <h2>2022 - 2024</h2>
        <Container>
            <SubmitButton href="https://polkadot.network"><span>polkadot.network</span></SubmitButton>
            &nbsp;
            <SubmitButton href="https://substrate.io"><span>substrate.io</span></SubmitButton>
            &nbsp;
            <SubmitButton href="https://docs.substrate.io"><span>docs.substrate.io</span></SubmitButton>
            &nbsp;
        </Container>
        <h2>2021</h2>
        <Container>
            <SubmitButton href="https://consensys.net"><span>consensys.net</span></SubmitButton>
            &nbsp;
            <SubmitButton href="https://metamask.io"><span>metamask.io</span></SubmitButton>
        </Container>
        <h2>2020 and before</h2>
        <Container>
            <SubmitButton href="https://cardano.org"><span>cardano.org</span></SubmitButton>
            &nbsp;
            <SubmitButton href="https://roadmap.cardano.org"><span>roadmap.cardano.org</span></SubmitButton>
            &nbsp;
            <SubmitButton href="https://cips.cardano.org"><span>cips.cardano.org</span></SubmitButton>
            &nbsp;
            <SubmitButton href="https://docs.cardano.org"><span>docs.cardano.org</span></SubmitButton>
            &nbsp;
            <SubmitButton href="https://why.cardano.org"><span>why.cardano.org</span></SubmitButton>
        </Container>
        <Container>
            <SubmitButton href="https://iohk.io"><span>iohk.io</span></SubmitButton>
            &nbsp;
            <SubmitButton href="https://daedaluswallet.io"><span>daedaluswallet.io</span></SubmitButton>
            &nbsp;
        </Container>
        </Wrapper>
    </Layout>
  );
};

export default PageTemplate;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 0;
  max-width: 100%;
  padding: 0 1.0875rem 1.45rem;
  padding-top: 0;
  `;
const Container = styled.div`
width: 100%;
padding: 1.5rem;
margin: 1rem;
border: 1px solid rgba(255, 255, 255, 0.5);
background-color: rgba(255, 255, 255, 0.3);
backdrop-filter: blur(10px);
border-radius: 8px;
display: flex;
flex-wrap: wrap;
justify-content: flex-start;
align-content: space-around;
flex-direction: row;
box-shadow: 0 4px 30px rgba(0, 0, 0, 1);
&:hover {
    transition: 0.2s;

}
& h2 {
    margin-bottom: 1rem;
    display: block;
    width: 100%;
}
& a {
    color: #000;
}
& a:hover {
    color: #fff;
}
& p {
  word-break: break-all;
  white-space: normal;
}
`;

const SubmitButton = styled.a`
  width: 250px;
  font-size: 16px;
  margin-top: 1rem;
  padding: 0.45rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid whitesmoke;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
  &:hover {
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.8);
  }
`


export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;